import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import Flex from "../components/flex";
import Box from "../components/box";
import Wrapper from "../components/wrapper";

import { graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const IntroText = styled(Box)`
  h2 {
    font-size: 36px;
    line-height: 1.4;

    em {
      font-family: "Karla", sans-serif;
    }

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 1.4;
    }
  }
`;

const BottomText = styled(Box)`
  font-size: 36px;
  line-height: 1.4;
  font-family: "PPEiko";
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 1.4;
  }

  em {
    font-family: "Karla", sans-serif;
    font-style: normal;
    font-weight: lighter;
  }
`;

const BiosText = styled(Box)`
  text-align: center;
  padding: 0 2em;
  img {
    max-width: 100%;
  }
  @media (max-width: 768px) {
  }

  h3 {
    font-size: 22px;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 0.5em;
  }

  em {
    font-family: "Karla", sans-serif;
    margin-bottom: 5vw;
    display: block;
    font-size: 16px;
  }
`;

// markup
const IndexPage = () => {
  const { t, language } = useI18next();

  // const artists = t("artistes", { ns: "artistes" });
  // const spectacles = t("spectacles", { ns: "spectacles" }).slice(0, 3);

  return (
    <Layout theme="dark" position="sticky" bg="#fafaf7">
      <Helmet>
        {language && language === "en" ? (
          <title>La Maison Mère - Record Label</title>
        ) : (
          <title>La Maison Mère - Maison de disques</title>
        )}
        <meta
          name="description"
          content="La Maison Mère (auparavant Turn Key Creative) est une maison de disques qui se démarque par son
éthique de travail et son engagement."
        />
      </Helmet>
      <Flex minHeight={[null, null, null, "80vh"]} alignItems="center">
        <Wrapper>
          <IntroText py={5}>
            <ReactMarkdown
              components={{
                code: ({ node, children, ...props }) => (
                  <div
                    style={{
                      position: "relative",
                      width: "1.125em",
                      display: "inline-block",
                      height: "1em",
                    }}
                  >
                    <img
                      style={{
                        display: "inline",
                        maxHeight: "1.125em",
                        borderRadius: "100%",
                        verticalAlign: "middle",
                        position: "relative",
                        top: "0",
                        left: "0",
                      }}
                      src={`/assets/${children}.jpg`}
                      {...props}
                    />
                  </div>
                ),
              }}
            >
              {t("content", { ns: "accueil" })}
            </ReactMarkdown>
          </IntroText>
        </Wrapper>
      </Flex>
      {/* <Flex bg="#211A1A" color="#fafaf7" alignItems="center" py={[3, "5vw"]}>
        <Wrapper>
          <Flex alignItems="center" my={3}>
            <h2 style={{ fontSize: "5vw" }}>
              {t("title", { ns: "artistes" })}
            </h2>
            <Box px={5}>
              <Link to="/artistes">
                {language && language === "en" ? "View All" : "Voir tous"}
              </Link>
            </Box>
          </Flex>
          <ArtistRow artists={artists} title={t("title", { ns: "artistes" })} />
        </Wrapper>
      </Flex> */}
      {/* <Flex bg="#44524B" color="#fafaf7" alignItems="center" py={[3, "5vw"]}>
        <Wrapper>
          <Flex alignItems="center" my={3}>
            <h2 style={{ fontSize: "5vw" }}>
              {t("title", { ns: "spectacles" })}
            </h2>
            <Box px={5}>
              <Link to="/spectacles">
                {language && language === "en" ? "View All" : "Voir tous"}
              </Link>
            </Box>
          </Flex>
          <SpectaclesRow
            spectacles={typeof spectacles === "object" ? spectacles : null}
            empty={t("empty", { ns: "spectacles" })}
          />
        </Wrapper>
      </Flex> */}
      <Flex bg="#fafaf7" py={[3]}>
        <Wrapper>
          <Flex flexWrap="wrap" justifyContent="space-between">
            <BottomText width={[1, 2 / 3]}>
              <ReactMarkdown>{t("content2")}</ReactMarkdown>
            </BottomText>
            <BiosText width={[1, 1 / 4]}>
              <ReactMarkdown
                components={{
                  img: ({ node, children, ...props }) => (
                    <span
                      style={{ width: "66%", display: "block", margin: "auto" }}
                    >
                      <span
                        style={{
                          display: "block",
                          position: "relative",
                          paddingTop: "100%",
                          overflow: "hidden",
                          borderRadius: "100%",
                        }}
                      >
                        <img
                          style={{
                            objectPosition: "bottom",
                            display: "block",
                            objectFit: "cover",
                            position: "absolute",
                            top: "0",
                            left: "0",
                          }}
                          {...props}
                        />
                      </span>
                    </span>
                  ),
                }}
              >
                {t("bios")}
              </ReactMarkdown>
            </BiosText>
          </Flex>
        </Wrapper>
      </Flex>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["accueil"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
